import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import styled from 'styled-components';
import {message, Spin, Result, Collapse} from 'antd';
import {errorHandler} from '../../errors';
import {useOutlet} from 'reconnect.js';
import {PROMOTION_TYPE} from '../../dictionary';
import Tabs from '../../Widgets/Tabs';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import IconButton from '../../Widgets/IconButton';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import Hint from '../../Widgets/Hint';
import PromotionDialog from '../../Dialogs/PromotionDialog';

const appConfig = require('../../data.json');

export default function PromotionPage(props) {
  const {pageContext} = props;
  const {type} = pageContext;
  const [record, setRecord] = useState();
  const [records, setRecords] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [actions] = useOutlet('actions');

  const getGroups = useCallback(async () => {
    try {
      let resp = await actions.getUserGroups({});
      setGroups(resp);
    } catch (error) {
      errorHandler(error, '取得會員群組錯誤');
    }
  }, [actions]);

  const getData = useCallback(
    async (type, group) => {
      async function getFeedback() {
        console.log('get credit feedback');
        let resp = await actions.getPromotionFeedback({
          user_group: group.id,
        });
        setRecord(resp);
      }
      async function getBonus() {
        let resp = await actions.getPromotionBonus({
          user_group: group.id,
        });
        setRecord(resp);
      }

      async function getDiscount() {
        let resp = await actions.getPromotionDiscount({
          user_group: group.id,
        });
        setRecord(resp);
      }

      async function getGiftBonuses() {
        let resp = await actions.getPromotionBonusGift({
          user_group: group.id,
          ordering: '-created',
        });
        // resp typeof array
        // backend not support filtering user_group
        // filter
        resp = resp.filter((x) => x.user_group === group.id);
        // get last one
        // if (resp.length > 0) {
        //   let gift = resp[resp.length - 1];
        //   setRecord(gift);
        // }
        setRecords(resp);
      }

      async function getPromotionThreshold() {
        let resp = await actions.getPromotionThreshold({
          user_group: group.id,
          ordering: '-created',
        });

        setRecords(resp);
      }

      try {
        actions.setLoading(true);
        switch (type) {
          case PROMOTION_TYPE.CREDIT_FEEDBACK:
            await getFeedback();
            break;
          case PROMOTION_TYPE.BONUS_FEEDBACK:
            await getBonus();
            break;
          case PROMOTION_TYPE.MEMBER_DISCOUNT:
            await getDiscount();
            break;
          case PROMOTION_TYPE.GIFT_BONUS:
            await getGiftBonuses();
            break;
          case PROMOTION_TYPE.THRESHOLD_DISCOUNT:
            await getPromotionThreshold();
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err);
        message.error('無法取得最新優惠，可能尚未建立');
      }
      actions.setLoading(false);
    },
    [actions],
  );

  const triggerGiftBonus = async (gift_id) => {
    if (!window.confirm(`確定要發放紅利給${selectedGroup.name}的所有會員?`)) {
      return;
    }

    actions.setLoading(true);
    try {
      await actions.triggerBonusGift(gift_id);
      await getData(type, selectedGroup);
      message.success('發放成功！');
    } catch (err) {
      errorHandler(err);
    }
    actions.setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    // initialize
    setRecord(null);
    setRecords([]);
    if (selectedGroup) {
      getData(type, selectedGroup);
    }
  }, [type, selectedGroup]);

  return (
    <Wrapper>
      <Block>
        <div className="row" style={{margin: 0}}>
          <h2>目前優惠設定</h2>
          <div style={{flex: 1}} />
          <Button
            disabled={
              !selectedGroup ||
              (record &&
                [
                  'CREDIT_FEEDBACK',
                  'BONUS_FEEDBACK',
                  'MEMBER_DISCOUNT',
                  'LOGISTIC_FEE',
                ].includes(type))
            }
            onClick={() => {
              setRecord(null);
              setOpenDialog(true);
            }}
            style={{marginRight: 10}}>
            {'新增'}
          </Button>
          <Button
            type="primary"
            onClick={() => getData(type, selectedGroup)}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
        <div className="divider" />
        <div>
          <Tabs
            value={selectedGroup?.id}
            onChange={(value, option) => {
              const group = groups.find((g) => g.id === value);
              setSelectedGroup(group);
            }}
            options={groups.map((g) => ({label: g.name, value: g.id}))}
            style={{marginBottom: 20}}
          />
          {
            !selectedGroup ? (
              <Result
                title="請指定會員群組"
                subTitle="請選擇欲使用此優惠的會員群組。"
                status="info"
              />
            ) : !record && records.length === 0 ? (
              <Result
                title="無優惠顯示"
                subTitle="此會員群組尚未建立此優惠，請點選新增。"
                status="info"
                extra={
                  [
                    'CREDIT_FEEDBACK',
                    'BONUS_FEEDBACK',
                    'MEMBER_DISCOUNT',
                    'LOGISTIC_FEE',
                  ].includes(type) ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        setOpenDialog(true);
                        // TODO: create
                      }}>
                      新增
                    </Button>
                  ) : (
                    false
                  )
                }
              />
            ) : type === PROMOTION_TYPE.MEMBER_DISCOUNT && record ? (
              <Collapse defaultActiveKey={0}>
                <Collapse.Panel
                  header={`# ${record.id}`}
                  extra={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      hoverColor={appConfig.colors.sub}>
                      <Edit />
                    </IconButton>
                  }>
                  <div className="row">
                    <h4>優惠種類</h4>
                    <div>會員打折</div>
                  </div>
                  <div className="row">
                    <h4>優惠</h4>
                    <div>全站買商品 {record.value * 100}% off</div>
                  </div>
                  <div className="row">
                    <h4>使用期限</h4>
                    <div>{record.expire_date}</div>
                    <ExpireHint date={record.expire_date} />
                  </div>
                </Collapse.Panel>
              </Collapse>
            ) : type === PROMOTION_TYPE.CREDIT_FEEDBACK && record ? (
              <Collapse defaultActiveKey={0}>
                <Collapse.Panel
                  header={`# ${record.id}`}
                  extra={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      hoverColor={appConfig.colors.sub}>
                      <Edit />
                    </IconButton>
                  }>
                  <div className="row">
                    <h4>優惠種類</h4>
                    <div>回饋點數</div>
                  </div>
                  <div className="row">
                    <h4>儲值點數送</h4>
                    <div>{parseInt(record.value * 100)} % 點數回饋</div>
                  </div>
                  <div className="row">
                    <h4>回饋限制(點)</h4>
                    <div>{record.limit} 點</div>
                  </div>
                  <div className="row">
                    <h4>使用期限</h4>
                    <div>{record.expire_date}</div>
                    <ExpireHint date={record.expire_date} />
                  </div>
                  <div className="row">
                    <h4>注意</h4>
                    <div>購買點數，並且付款完成後，獲得點數</div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            ) : type === PROMOTION_TYPE.BONUS_FEEDBACK && record ? (
              <Collapse defaultActiveKey={0}>
                <Collapse.Panel
                  header={`# ${record.id}`}
                  extra={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDialog(true);
                      }}
                      hoverColor={appConfig.colors.sub}>
                      <Edit />
                    </IconButton>
                  }>
                  <div className="row">
                    <h4>優惠種類</h4>
                    <div>回饋紅利(送紅利)</div>
                  </div>
                  <div className="row">
                    <h4>贈送紅利(元)</h4>
                    <div>
                      下單 每 {record.step} 元送 {record.value} 紅利
                    </div>
                  </div>
                  <div className="row">
                    <h4>回饋上限(元)</h4>
                    <div>{record.limit} 元</div>
                  </div>
                  <div className="row">
                    <h4>使用期限</h4>
                    <div>{record.expire_date}</div>
                    <ExpireHint date={record.expire_date} />
                  </div>
                  <div className="row">
                    <h4>注意</h4>
                    <div>
                      訂單完成後才會將紅利回饋至客戶，購買點數不會有紅利回饋。
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            ) : type === PROMOTION_TYPE.GIFT_BONUS && records.length > 0 ? (
              <Collapse defaultActiveKey={0}>
                {records.map((record, idx) => (
                  <Collapse.Panel
                    key={idx}
                    header={`# ${record.id}`}
                    extra={
                      <Button
                        disabled={record.enabled || idx !== 0}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          triggerGiftBonus(record.id);
                        }}>
                        發放
                      </Button>
                    }>
                    <div className="row">
                      <h4>優惠種類</h4>
                      <div>撒紅利</div>
                    </div>
                    <div className="row">
                      <h4>紅利數量</h4>
                      <div>{record.value} 點</div>
                    </div>
                    <div className="row">
                      <h4>發放狀態</h4>
                      <div>
                        {record.enabled
                          ? '已發放，如要再發放請建立新的撒紅利活動!'
                          : '尚未發放'}
                      </div>
                    </div>
                    <div className="row">
                      <h4>發放時間</h4>
                      <div>
                        {record.enable_time?.replace('T', ' ')?.split('.')[0] ||
                          '-'}
                      </div>
                    </div>
                    <div className="row">
                      <h4>建立時間</h4>
                      <div>{record.created.slice(0, 10)}</div>
                    </div>
                    <div className="row">
                      <h4>注意</h4>
                      <div>
                        撒紅利是背景執行，並非馬上完成，完成所需時間視會員多寡而定。
                      </div>
                    </div>
                  </Collapse.Panel>
                ))}
              </Collapse>
            ) : type === PROMOTION_TYPE.THRESHOLD_DISCOUNT &&
              records.length > 0 ? (
              <Collapse defaultActiveKey={0}>
                {records.map((record, idx) => (
                  <Collapse.Panel
                    key={idx}
                    header={`# ${record.id}`}
                    extra={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setRecord(record);
                          setOpenDialog(true);
                        }}
                        hoverColor={appConfig.colors.sub}>
                        <Edit />
                      </IconButton>
                    }>
                    <div className="row">
                      <h4>優惠種類</h4>
                      <div>滿額折扣</div>
                    </div>
                    <div className="row">
                      <h4>活動名稱</h4>
                      <div>{record.name}</div>
                    </div>
                    <div className="row">
                      <h4>優惠</h4>
                      <div>
                        商品金額 {record.threshold} 元，可折抵 {record.value} 元
                      </div>
                    </div>
                    <div className="row">
                      <h4>使用期限</h4>
                      <div>{record.expire_date}</div>
                      <ExpireHint date={record.expire_date} />
                    </div>
                    <div className="row">
                      <h4>備註</h4>
                      <div>
                        可新增多筆，系統會以商品金額選擇最大滿足門檻之優惠
                      </div>
                    </div>
                  </Collapse.Panel>
                ))}
              </Collapse>
            ) : type === PROMOTION_TYPE.LOGISTIC_FEE && record ? null : null // PromotionLogisticFee page
          }
        </div>
      </Block>

      <PromotionDialog
        title={'修改優惠'}
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        type={type}
        selectedGroup={selectedGroup}
        record={record}
        onUpdate={() => getData(type, selectedGroup)}
      />
    </Wrapper>
  );
}

function ExpireHint({date}) {
  const _date = new Date(date);
  _date.setHours(23, 59, 59, 999);
  if (_date < new Date()) {
    return <Hint type="warning">此優惠已過期</Hint>;
  }
  return null;
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
