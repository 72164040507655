import React from 'react';
import styled from 'styled-components';
import Row from './Row';
const appConfig = require('../data.json');

export default function Tabs({value, onChange, options = [], style = {}}) {
  return (
    <Row style={style}>
      {options.map((option, idx) => {
        const selected = value === option.value;
        return (
          <React.Fragment key={idx}>
            {idx !== 0 && <Gap />}
            <Tab
              key={idx}
              className={
                (selected ? 'selected ' : '') +
                (option.disabled ? 'disabled ' : '')
              }
              onClick={() => {
                if (!option.disabled) {
                  onChange(option.value, option);
                }
              }}>
              {option.label}
            </Tab>
          </React.Fragment>
        );
      })}
      <Blank />
    </Row>
  );
}

const Tab = styled.div`
  padding: 12px 30px;
  border: solid 1px #eee;
  background-color: #f5f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &.selected {
    border-bottom-color: #fff;
    background-color: #fff;
    color: ${appConfig.colors.main};
  }

  &:hover {
    color: ${appConfig.colors.main};
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Gap = styled.div`
  border-bottom: solid 1px #eee;
  align-self: stretch;
  width: 5px;
`;
const Blank = styled.div`
  border-bottom: solid 1px #eee;
  align-self: stretch;
  flex: 1;
`;
